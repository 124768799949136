<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="empName" placeholder="姓名"></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="shopId" placeholder="店铺" clearable>
            <el-option
                v-for="item in shopArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="role" placeholder="职务" clearable>
            <el-option
                v-for="item in roleArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="isValid" placeholder="是否在职" clearable>
            <el-option
                v-for="item in validArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 10px;padding-left: 10px">
      <el-button type="primary" @click="dialog.addEmpState = true">添加员工</el-button>
      <per-button type="success" @click="dialogState = true" per="emp:super_password">通用密码</per-button>
    </el-row>
    <el-row style="margin-top: 10px;padding-left: 10px">
      <el-table
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :data="table.empList"
          border
          max-height="620"
          :default-sort = "{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column prop="empName" label="姓名" align="center"></el-table-column>
        <el-table-column prop="position" label="职务" width="300" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="shopName" label="店铺" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="empPhone" label="电话" align="center" ></el-table-column>
        <el-table-column label="是否在职" align="center" #default="scope">
          <p v-if="scope.row.isValid === 1" style="color: #017ffd">在职</p>
          <p v-else style="color: red">离职</p>
        </el-table-column>
        <el-table-column prop="createTime" label="入职时间" align="center" ></el-table-column>
        <el-table-column prop="empRemark" label="备注" align="center" ></el-table-column>
        <el-table-column width="350" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="success" size="mini" @click="openEdit(scope.row)">编辑</el-button>
              <el-button type="warning" size="mini" @click="deleteEmp(scope.row)">删除</el-button>
              <el-button type="danger" size="mini" @click="resetPwd(scope.row)">重置密码</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100, 500, 1000]"
          :page-size="1000"
          layout="prev, pager, next, total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <empAdd v-if="dialog.addEmpState" :state="dialog.addEmpState" @close="close"/>
    <empEdit v-if="dialog.editEmpState" :emp="dialog.emp" :state="dialog.editEmpState" @close="close"/>
    <el-dialog
        v-if="dialogState"
        title="设置通用密码"
        width="30%"
        :visible.sync="dialogState"
        :modal-append-to-body="false"
        @close="spwdClose"
        center
    >
      <el-form label-width="100px" :model="form" :rules="rules" ref="editForm">
        <el-row>
          <el-col :span="21">
            <el-form-item label="通用密码" prop="superPassword">
              <el-input v-model="form.superPassword"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="spwdClose">取 消</el-button>
        <el-button size="medium" type="primary" @click="submit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import empAdd from "@/pages/emp/emp/emp-add";
import empEdit from "@/pages/emp/emp/emp-edit";

export default {
  name: "emp-manager",
  data(){
    return{
      empName:"",
      shopId:"",
      role:"",
      isValid:1,
      tenantCrop:localStorage.getItem("tenantCrop"),
      table:{
        empList:[],
        page:1,
        limit:1000,
        total:0,
      },
      shopArray:[],
      roleArray:[],
      dialog:{
        addEmpState:false,
        editEmpState:false,
        emp:{},
      },
      validArray: [
          {name: "在职", value: 1},
          {name: "离职", value: 0},
      ],
      dialogState: false,
      form: {
        id: null,
        superPassword: "",
      },
      rules: {
        superPassword: [
          {required: true, message: '请输入通用密码', trigger: 'blur'},
        ],
      },
    }
  },
  components:{
    empAdd,
    empEdit,
  },
  created() {
    this.queryEmpList();
    this.queryRoleArray();
    this.queryShopArray();
    this.querySuperPwd()
  },
  methods:{
    search(){
      this.table.page=1;
      this.queryEmpList();
    },
    querySuperPwd() {
      this.$axios({
        method: "get",
        url: "/login/querySuperPassword",
      }).then(response => {
        this.form = response.data.data
      })
    },
    //查询员工列表
    queryEmpList:function (){
      this.$axios({
        method:"GET",
        url:"/emp/loadTenantEmp",
        params:{
          page:this.table.page,
          limit:this.table.limit,
          empName:this.empName,
          shopId:this.shopId,
          role:this.role,
          isValid:this.isValid,
          tenantCrop:this.tenantCrop,
        }
      }).then(response=>{
        this.table.total=response.data.data.total;
        this.table.empList=response.data.data.list;
      })
    },
    //打开编辑界面
    openEdit(data){
      this.dialog.editEmpState = true
      this.dialog.emp = data
    },
    //查询权限
    queryRoleArray:function (){
      this.$selectUtils.queryRoleIds().then(response=>{
        this.roleArray=JSON.parse(response.data.data)
      })
    },
    //查询店铺
    queryShopArray:function (){
      this.$selectUtils.shopIdsIsValid().then(response=>{
        this.shopArray=JSON.parse(response.data.data)
      })
    },
    //删除客户
    deleteEmp(data){
      this.$confirm("删除客户", '删除客户:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method:"delete",
          url:"/emp/deleteEmp",
          params:{
            id:data.id
          }
        }).then(response=>{
          if (response.data.code === 200) {
            this.$message.success("删除成功!")
            let self=this;
            setTimeout(function (){self.queryEmpList()},1000)
          } else {
            this.$message.error(response.data.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //重置密码
    resetPwd(data){
      this.$confirm("是否确定重置密码?", '重置密码:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method:"POST",
          url:"/emp/resetEmpPwd",
          params:{
            id:data.id
          }
        }).then(response=>{
          if (response.data.code === 200) {
            this.$message.success("重置成功!")
            let self=this;
            setTimeout(function (){self.queryEmpList()},1000)
          } else {
            this.$message.error(response.data.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //更改每页长度
    handleSizeChange:function (val){
      this.table.limit=val;
      this.queryEmpList();
    },
    handleCurrentChange(val) {
      this.table.page =val
      this.queryEmpList()
    },
    close(){
      this.dialog.addEmpState=false
      this.dialog.editEmpState=false
      this.queryEmpList();
    },
    spwdOpen() {
      this.dialogState = true
    },
    spwdClose() {
      this.dialogState = false
      this.querySuperPwd()
    },
    submit() {
      this.$axios({
        method: "post",
        url: "/login/updateSuperPassword",
        data: {
          ...this.form
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success("修改成功!")
          let self = this;
          setTimeout(function () {
            self.spwdClose()
          }, 700)
        } else {
          this.$message.error(response.data.msg)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>