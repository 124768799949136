<template>
  <el-dialog
      title="添加员工"
      width="55%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 40px" ref="form">
      <el-row>
        <el-col :span="7">
          <el-form-item label="员工姓名" prop="empName">
            <el-input v-model="form.empName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="员工职务" prop="empRole">
            <el-select v-model="form.empRole" multiple collapse-tags placeholder="请选择员工职务">
              <el-option
                  v-for="item in roleArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="所在店铺" prop="shopId">
            <el-select v-model="form.shopId" multiple collapse-tags placeholder="请选择所在店铺" @change="test">
              <el-option
                  v-for="item in shopArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="电话" prop="empPhone">
            <el-input type="number" v-model="form.empPhone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 4}"
            placeholder="请输入内容"
            v-model="form.remark">
        </el-input>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="medium">取 消</el-button>
        <el-button type="primary" @click="addEmpCheck()" size="medium">提 交</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "emp-add",
  data() {
    return {
      form: {
        //公司名
        empName: "",
        empRole: [],
        shopId: [],
        empPhone: "",
        isValid: 1,
        remark: "",
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      rules: {
        empName: [
          {required: true, message: '请输入员工名', trigger: 'blur'},
        ],
        empRole: [
          {required: true, message: '请选择员工职务', trigger: 'change'},
        ],
        shopId: [
          {required: true, message: '请选择店铺', trigger: 'change'},
        ],
        empPhone: [
          {required: true, message: '请输入员工名', trigger: 'blur'},
          {pattern:/^1\d{10}$/,message: "手机号格式有误"}
        ],
      },
      shopArray: [],
      roleArray: [],
      dialogState: this.state,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  created() {
    this.queryShopIdsIsValidArray();
    this.queryRoleArray();
  },
  methods: {
    //检查订单校验
    addEmpCheck: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.addEmp();
        }
      })
    },
    //添加员工
    addEmp: function () {
      let value = "是否添加该员工?"
      this.$confirm(value, '添加员工:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/emp/addEmp",
          params: {
            empName: this.form.empName,
            shopIds: this.form.shopId.toString(),
            empPhone: this.form.empPhone,
            isValid: this.form.isValid,
            remark: this.form.remark,
            roleIds: this.form.empRole.toString(),
            tenantCrop: localStorage.getItem("tenantCrop"),
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("添加成功!")
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //查询权限
    queryRoleArray:function (){
      this.$selectUtils.queryRoleIds().then(response=>{
        this.roleArray=JSON.parse(response.data.data)
      })
    },
    //查询店铺
    queryShopIdsIsValidArray:function (){
      this.$selectUtils.shopIdsIsValid().then(response=>{
        this.shopArray=JSON.parse(response.data.data)
        this.form.shopId.push(this.shopArray[0].value)
      })
    },
    close: function () {
      this.$emit("close", false);
    },
    test(val) {
      console.log(val.toString())
    }
  }
}
</script>

<style scoped>

</style>